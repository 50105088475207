<template>
  <div class="d-print-none">
    <v-list
      nav
      dense
    >
    
      <v-list-item  :link="type != 'Admin'"
        :to="type != 'Admin' ? {name:'Page'+type+'AccountBrowse'} : '#'">
        <v-list-item-icon>
          <v-icon v-if="type == 'Admin'">mdi-shield-account</v-icon>
          <v-icon v-else-if="type == 'Moderator'">mdi-account-tie</v-icon>
          <v-icon v-else>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{auth.User.name}}</v-list-item-title>
      </v-list-item>
      <!-- <v-list-item 
        v-if="type != 'Admin'"
        link
        :to="{name:'Page'+type+'AccountBrowse'}"
      >
        <v-list-item-icon>
          <v-icon>mdi-town-hall</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Account {{account.id}}</v-list-item-title>
        <v-list-item-icon>
          <v-icon>mdi-chevron-down</v-icon>
        </v-list-item-icon>
      </v-list-item> -->
    </v-list>
    <v-divider></v-divider>
    <v-list
      nav
      dense
    >
      <v-list-item 
        link
        :to="{name:'PageMainDiscover'}"
      >
        <v-list-item-icon>
          <v-icon>mdi-magnify</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("route.PageMainDiscover") }}</v-list-item-title>
      </v-list-item>
      <!--
      <v-list-item 
        link
        :to="{name:'PageMainEvent'}"
      >
        <v-list-item-icon>
          <v-icon>mdi-calendar-star</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("route.PageMainEvent") }}</v-list-item-title>
      </v-list-item>
      -->
    </v-list>
    <v-divider></v-divider>
    <v-list
      nav
      dense
    >
      <template v-for="(item,index) in menu">
        <v-list-item 
          link
          :to="item.route"
          :key="index"
        >
          <v-list-item-icon>
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-if="type == 'Admin'">{{item.name}}</v-list-item-title>
          <v-list-item-title v-else>{{$t(item.translatableName)}}</v-list-item-title>
        </v-list-item>
        <v-divider  :key="index+'-divider'" v-if="item.divider" class="py-1"></v-divider>
      </template>
    </v-list>
  </div>
  </template>
  
  
  <script>
    import { mapState } from 'vuex'
    import menu from '@/datasets/menu'
    export default {
      computed: mapState({
        auth: state => state.auth.data,
        account: state => state.account.data,
      }),
      data: () => ({
        menu:[],
        //BOC:[type]
        type:null,
        //EOC
      }),
      created() {
        //BOC:[role]
        this.type = this.$_ucfirst(this.auth.Account.type)
        //EOC
        this.menu = this.$_.clone(menu[this.type])
        this.menu.unshift({
          name:"Dashboard",
          translatableName:"model.name.dashboard",
          icon:'mdi-view-dashboard',
          route:{name:'Page'+this.type+'Dashboard'},
        })
        this.menu.push({
          name:'Report',
          translatableName:"action.report",
          icon:'mdi-file-chart-outline',
          route:{name:'Page'+this.type+'Report'},
        })
        this.menu.push({
          name:'Log Out',
          translatableName:"action.logout",
          icon:'mdi-exit-run',
          route:{name:'PageMainLogout'},
        })
      },
    }
  </script>
  