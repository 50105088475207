export default {
  action: {
    assign_classroom:"分配教室",
    assign_event:"分配事件",
    assign_other_school:"分配其他学校教室",
    assign_school_year:"分配学年",
    assign_school: "分配学校",
    back:"返回",
    cancel: '取消',
    click_here:"点击这里",
    confirm: "确认",
    copy:"复制",
    copied:"已复制!",
    delete:"删除",
    download:"下载",
    download_excel:"下载Excel",
    download_image:"下载图片",
    edit:"编辑",
    generate:"产生",
    generate_pass:"生成快速通行证",
    info: "资讯",
    join: "马上参加",
    login: '登入',
    login_now: "马上登入",
    logout:"登出",
    manage:"管理",
    manage_pass:"管理快速通行证",
    manage_prize:"管理奖品",
    manage_questions:"管理问题",
    new_classroom:"新教室",
    new_event:"新事件",
    new_festival: "新节日",
    new_institution: "新机构",
    new_school:"新学校",
    new_sponsor: "新赞助商",
    new_student:"新生",
    new_teacher: "新老师",
    next:"下页",
    no:"不",
    ok:"好的",
    orderBy: "排序",
    play: "游玩",
    playAgain: "再玩一遍",
    previous:"上页",
    print:"打印",
    quick_setup:"快速设置",
    recalculate:"重新计算",
    regenerateQuestion: "更新问题",
    remove:"消除",
    replace: "更换",
    replaceImage: "更换图片",
    report: "报告",
    returnHome: "返回主页",
    save:"保存",
    search: "搜索",
    select:"选择",
    selected:"已选中",
    send_invitation:"发送邀请",
    send_notice:"发送通知",
    send_pass:"发送通行证",
    set_password:"设置密码",
    scoreboard:"记分牌",
    share:"分享",
    start: "开始",
    testPlay: "试玩",
    view: "查阅",
    viewMore:"查看更多",
    viewSample: "查阅例题",
    view_templates:"查看模板",
    whatsapp: "Whatsapp 我们",
    yes:"是的",
  },
  api: {
    response: {
      no_data: "没有相关数据。",
    },
  },
  model: {
    filterBy: {
      all: "全部",
    },
    name: {
      account:"账号",
      accounts:"账号",
      achievement:"成就",
      achievements:"成就",
      achievementlevel:"成就水平",
      achievementlevels:"成就水平",
      activity:"活动",
      application:"申请表",
      applications:"申请表",
      attempts:"尝试",
      book:"故事书",
      books:"故事书",
      building:"建造",
      buildings:"建筑物",
      chapter: "单元",
      chapters: "单元",
      checkpoint: "课题",
      checkpoints: "课题",
      classroom: "班级",
      classrooms: "班级",
      dailyGameProgress: "每日数学",
      dashboard:"仪表板",
      event: "活动",
      events: "活动",
      eventSponsor: "Event Sponsors",
      feature:"功能",
      features:"功能",
      // festival:"活动",
      // festivals:"活动",
      finisher:"完赛者",
      finishers:"完赛者",
      game:"游戏",
      institution: "学校",
      institutions: "学校",
      inventory:"库存",
      inventories:"库存",
      job: "工作",
      jobs: "工作",
      labRecord: "笔记",
      labRecords: "笔记",
      member:"成员",
      members:"成员",
      news:"消息",
      page:"页面",
      pages:"页面",
      option:"選擇",
      options:"選擇",
      participant: "参赛者",
      participants: "参赛者",
      payment:"付款",
      payments:"付款",
      performance:"表现",
      plan:"配套",
      plans:"配套",
      player:"玩家",
      players:"玩家",
      pricing:"定价",
      prize: "奖项",
      prizes: "奖项",
      quest: "任务",
      quests: "任务",
      record: "记录",
      records: "记录",
      rewards:"奖励",
      rule:"指南",
      rules:"指南",
      series:"锦标赛",
      step:"步",
      steps:"步",
      topic:"话题",
      topics:"话题",
      question: "题目",
      questions: "题目",
      school: "学校",
      schools: "学校",
      schoolyear:"学年",
      schoolyears:"学年",
      sponsor:"赞助",
      sponsors:"赞助商",
      sponsorship:"赞助",
      sponsorshippackage:"赞助配套",
      sponsorshippackages:"赞助配套",
      sponsoredstudent:"受资助学生",
      sponsoredstudents:"受资助学生",
      student: "学生",
      students: "学生",
      submission: "题卷",
      submissions: "题卷",
      subscription: "订阅",
      subscriptions: "订阅",
      syllabus: "课程纲要",
      syllabuses: "课程纲要",
      teachers:"教师",
      teacher:"老师",
      theme:"绘画主题",
      themes:"绘画主题",
      user:"用户",
      users:"用户",
      winner: "得奖者",
      winners: "得奖者",
    },
    prop: {
      action:"行动",
      accuracy: "精准度",
      attendance:"出席率",
      attempt: "尝试次数",
      code:"代码",
      corrects: "正确",
      count:"数数",
      created:"已创建",
      data:"数据",
      dateEnd: "结束日期",
      dateStart: "开始日期",
      description:"描述",
      emblem: "校徽",
      end:"结尾",
      finishers:"完赛者",
      fullname: "姓名",
      gameUrl:"游戏网址",
      gender:"性别",
      isActive: "活跃",
      itemName: "奖品",
      lastUpdated:"最近更新时间",
      logo:"Image",
      message:"信息",
      mobile: "手机号码",
      name: "名字",
      nameClassroom:"班级名称",
      nameEvent:"活动名称",
      nameSchool:"学校名称",
      no: "#",
      participants: "参加者",
      password: "密码",
      progress:"进度",
      quantity: "数量",
      questions:"问题",
      subtitle:"字幕",
      schoolCode:"学校代码： ",
      schoolYear:"学年",
      score: "得分",
      stars: "星数",
      state:"状态",
      status: "状态",
      students:"学生",
      studentCode:"学生代码： ",
      submissions:"题卷",
      time: "时间",
      timeEnd:"时间结束",
      timeStart:"时间开始",
      timestampEnd: "结束",
      timestampStart: "开始",
      total:"总共",
      totalAttempt: "尝试次数",
      totalSubmission:"提交次数",
      totalScore: "总得分",
      totalStar: "总星数",
      town: "镇",
      tried:"尝试过",
      weakness: "弱项",
      websiteUrl:"网址",
      year:"年份",
    },
    value: {
      status: {
        absent: "缺席",
        active:"活跃",
        coming: "未开始",
        completed: "完成",
        disable:"禁用",
        enable:"使能够",
        inactive:"不活跃",
        joined: "参与中",
        ongoing: "进行中",
        past: "结束了",
      },
    },
  },
  route: {
    PageMainAccountLogin: '账号登入',
    PageMainApply: "Hamochi Edu-Cup 2024/2025 学校报名",
    PageMainDiscover: '问题库',
    PageMainDiscoverQuiz: "小测验",
    PageMainDiscoverSample: "例题",
    PageMainEvent: '活动',
    PageMainEventAnalysis: "分析",
    PageMainEventGuide: "活动指南",
    PageMainEventFollowup: "跟进",
    PageMainEventScoreboard: "记分牌",
    PageMainHome: '主页',
    PageMainLanguage: "更换语言",
    PageMainLogin: '登入',
    PageMainPricing: "配套与价格",
    PageMainRegister: '注册',
    PageMainUpgrade: "升级配套",
  },
  rules: {
    classroom:"课堂是必需的",
    confirm_password:"需要确认密码",
    count:"计数是必需的",
    event:"活动是必需的",
    general:"请检查表格的内容。",
    logo:"图像大小应小于 2 MB！",
    min8Characters:"至少8个字母。",
    mobile:"手机号码必须在 7 到 15 之间",
    mobileNumber:"手机号码必须是整数",
    name: "姓名为必填项",
    nameClassroom:"课堂名称为必填项",
    nameEvent:"活动名称为必填项",
    nameSchool:"学校名称为必填项",
    new_password:"需要新密码",
    notBlank:"请填入。",
    old_password:"旧密码是必需的",
    password:"密码必须为 4 个字符",
    passwordMustMatch:"密码需要相同。",
    town: "",
    websiteUrl:"网站网址为必填项",
  },
  counter_suffix:{
    duration_day: "天数",
    duration_hour: "小时",
    duration_minute: "分钟",
    duration_second: "秒",
    time_day: "天",
    time_hour: "时",
    time_minute: "分",
    time_second: "秒",
  },
  string: {
    bread_assign:"分配",
    bread_manage:"管理",
    bread_new:"新的",
    bread_template:"模板",
    error_construction:"准备中。",
    event_future: "未来的活动",
    event_latest: "刚结束的活动",
    event_ongoing: "进行中的活动",
    event_past: "过去的活动",
    event_upcoming: "即将到来的活动",
    express_classroom: "快捷教室",
    family:"家庭",
    info:"资料",
    info_institution: "学校资料",
    loading:"读取中。。。",
    menu:"菜单",
    my:"我的",
    my_institution:"我的学校",
    page:"页",
    pick_logo:"Select an image",
    presented_by:"为您呈现",
    recalculate:"如果总分或星星总数不正确，请点击以下按钮",
    recalculateScore:"马上重新计算？",
    recalculateStar:"马上重新计算？",
    question_prefix: "问题",
    sample_question: "例题",
    schoolYear_prefix:"",
    schoolYear_suffix:"年级",
    self_registration:"自行注册",
    search_by_name:"按名称搜索",
    step:"步骤",
    to:"至",
  },
  view: {
    PageBackendClassroom:{
      assign_event_title:"分配事件",
      self_registration:"自行注册",
    },
    PageBackendClassroomExpress: {
      express_pass_count: "快速通行证计数",
      generate_pass_title: "生成新的快速通行证",
      manage_pass_title: "管理快速通行证",
    },
    PageBackendEvent:{
      assign_classroom:"分配教室",
      assign_school_year:"分配学年",
      add_new_title:"新事件",
      edit_title:"编辑活动",
      image:"图片",
      invitation:"邀请函",
      manage_prize:"管理奖品",
      message:"信息",
      notice:"注意",
      pass:"经过",
      scoreboard:"记分牌",
      send_event_notice:"发送活动通知",
      send_pass:"发送通行证",
      send_scoreboard:"发送记分牌",
      send_syllabus:"发送教学大纲",
      set_password:"设置密码",
      template:"模板",
      template_name:"模板名称"
    },
    PageBackendPasswordChange: {
      confirm_password:"确认密码",
      new_password:"新密码",
      old_password: "旧密码",
      password_change_title: "更改密码",
    },
    PageBackendSchool:{
      invitation:"邀请函",
      quick_setup_title:"快速设置新学校"
    },
    PageBackendStudent:{
      add_new_1_description:"每行一名学生。",
      add_new_2_description:"您可以从 excel 文件中复制并粘贴名称。",
      add_new_3_description:"我们的系统会自动将“L”转换为“Male”，将“F”转换为“Female”。",
      add_new_title:"新生",
      step:"步",
    },
    PageBackendTeacher:{
      add_new_title: "新老师",
      edit_title: "编辑老师",
    },
    PageErrorNotFound: {
      title: "报告班长，我们失踪了。",
      description: "此网页不存在。",
    },
    PageErrorSessionExpired: {
      title: "报告班长，登入证过期了。",
      description: "为保护您的账号，请再次登入。",
    },
    PageMainDiscoverQuiz: {
      my_entry: "我的答案",
      my_score: "我的成绩",
      points: "得分",
      secs: "秒",
      time: "时间",
      total_questions: "共10题问题。",
    },
    PageMainEventScoreboardReport:{
      comment:{
        high:"棒极了！",
        low:"有进步的空间",
      },
      header:{
        accuracy:"准确性",
        avgCorrect:"平均正确率",
        avgQuestion:"平均问题",
        comment:"备注",
        event:"活动整体",
        school:"本校",
        topic:"话题",
      },
      improvement:{
        totalImprovedStudent: "进步的学生总数"
      },
      progress:{
        collectedAllCollections: "收集了所有收藏物品",
        completedTutorial:"完成教程",
        createdAvatar: "设立角色",
        finishedAllTopics:"完成所有课题",
        joined: "参与活动",
        playedOneLevel:"尝试一个课题",
        purchasedApparel:"购买服饰",
        registered:"注册",
      },
      stat:{
        students:"学生人数",
        participants:"参赛者人数",
        finishers:"完赛者人数",
        attendance:"参赛率",
        completed:"完赛率",
        submissions:"答卷数量",
        questions:"问题数量",
        corrects:"正确数量",
        accuracy:"正确率",
        averageQuestion:"平均一名参赛者解答的问题数量",
      },
    },
    PageMainEventScoreboard: {
      countdown_end: "结束于",
      countdown_ended: "已经结束了",
      countdown_start: "即将开始于",
      countdown_time_days: "天",
      countdown_time_hours: "时",
      countdown_time_minutes: "分",
      countdown_time_seconds: "秒",
      event_finisher_protection_title:"受保护的内容",
      event_finisher_protection_description:"请输入正确的代码。",
      event_finisher_protection_failed_title:"代码错误。",
      event_finisher_protection_failed_description:"请输入正确的学校代码。",
      event_finisher_status_all: "全部",
      event_finisher_status_completed: "完成",
      event_finisher_status_incomplete: "未完成",
      event_pdf_total_finisher:'完赛选手总数：',
      event_pdf_total_winner:'获奖者总数：',
      event_pdf_total_winner_by_school:'按学年划分的获奖者总数：',
      event_pdf_top5_winner:'按学年排名前 5 名获奖者：',
      event_pdf_winner_list:'获奖者名单：',
      highlight_title:"今日头条",
      highlight_1_shout:"哇，好多鱼啊！！！",
      highlight_2_shout:"虫虫我来啦！",
      highlight_1_content:"大丰收。目前领先。",
      highlight_2_content:"抓到很多蝴蝶并领先。",
      perfect_score:"没有弱项。",
      report:"活动分析",
    },
    PageMainEventScoreboardStudent:{
      missing_attempt:"个记录已被删除。系统会定期自动删除旧的记录。",
    },
    PageMainFestival:{
      password_title:"请输入学校代码",
      password_label:"学校代码",
    },
    PageMainFestivalRead:{
      analysis:"分析",
      join_error_title:"哎呀～",
      join_error_description:"别心急，活动将开始于",
      protection_title:"这活动有密码保护",
      protection_description:"请输入学校代码。",
      protection_action:"马上输入",
      protection_error:"缺少学校代码。",
      protection_failed_title:"代码错误。",
      protection_failed_description:"请输入正确的学校代码。",
      protection_failed_error:"学校代码错误。",
      sponsor_description:"衷心感谢各大赞助商对此活动的鼎力支持。因为有你们的支持和关怀，学生们有此机会体验学习的快乐！",
      tab_guide:"指南",
      tab_ranking:"排名",
      tab_student_allStudent:"全部学生",
      tab_student_top3:"前三名",
      tab_topic:"课题",
    },
    PageMainHome: {
      slide_client_title: '合作机构',
      slide_client_button_more: '阅览更多',
      slide_contact_title: '需要帮忙？',
      slide_contact_cta: '线上客服',
      slide_event_title:"活动主题",
      slide_faq_title: '常见问题（FAQ）',
      slide_faq_1_question: "需要付费吗？",
      slide_faq_1_answer: "不。完全免费。",
      slide_faq_2_question: "可以使用什么平台来玩？",
      slide_faq_2_answer: "手机或电脑。皆可。",
      slide_faq_3_question: "华为（HuaWei）手机可以使用吗？",
      slide_faq_3_answer: "可以。只需使用手机里内置的网络浏览器。",
      slide_faq_4_question: "有连接Google Gradebook吗？",
      slide_faq_4_answer: "暂时没有。如有更多的需求，会考虑开发。您若有兴趣，可以告知我们的团队。开发完成后会即刻通知您。",
      slide_faq_5_question: "有包含数学以外的科目吗？",
      slide_faq_5_answer: "暂时没有。如有更多的需求，会考虑开发。您若有兴趣，可以告知我们的团队。开发完成后会即刻通知您。",
      slide_faq_6_question: "老师可以变更出题范围吗？",
      slide_faq_6_answer: "可以。我们有提供出题范围的选择。",
      slide_faq_7_question: "如何确保学生输入正确的姓名？",
      slide_faq_7_answer: "我们有其他功能，可以预先帮学生开账号以确保学生的名字是正确的。",
      slide_faq_8_question: "如何预防学生们抄袭？",
      slide_faq_8_answer: "我们所有的问题都是人工智能在学生们游玩时当下制作出来的。就算是同一个课题，每次的问题都会有所不同。",
      slide_faq_9_question: "如何预防家长帮助孩子作答？",
      slide_faq_9_answer: "我们的产品正如平时的学校课业一般。老师们须提点家长不去干预孩子们做复习。",
      slide_feature_title: "活动特色",
      slide_feature_1_title: '轻松自在',
      slide_feature_1_description: '自动出题，自动成绩报表。',
      slide_feature_2_title: '充满意义',
      slide_feature_2_description: '巩固学生们的知识。',
      slide_feature_3_title: '生动可爱',
      slide_feature_3_description: '让学生们对学习产生兴趣。',
      slide_game_title:"轻松玩一玩！",
      slide_game_description:"无需注册。免费使用。",
      slide_game_button_play:"马上试玩",
      slide_game_image_caption:"最新的疯狂钓鱼活动（2022年度4月至6月）",
      slide_performance_title: "过往的活动表现",
      slide_performance_description: "2023年08月活动的数据",
      slide_performance_1: "80 间小学。",
      slide_performance_2: "18,604 名小学生。",
      slide_performance_3: "50% 出席活动。",
      slide_performance_4: "25% 参赛者完美的完成了所有课题。",
      slide_performance_5: "1,061,040 道问题被解答了。",
      slide_performance_6: "90% 道问题被正确的解答了。",
      slide_performance_7: "平均一位参赛者解答了 114 道问题。",
      // slide_performance_description: "2023年05月活动的数据",
      // slide_performance_1: "74 间小学。",
      // slide_performance_2: "18,252 名小学生。",
      // slide_performance_3: "66% 出席活动。",
      // slide_performance_4: "17% 参赛者完美的完成了所有课题。",
      // slide_performance_5: "1,159,740 道问题被解答了。",
      // slide_performance_6: "89% 道问题被正确的解答了。",
      // slide_performance_7: "平均一位参赛者解答了 64 道问题。",
      // slide_performance_description: "2022年12月活动的数据",
      // slide_performance_1: "38 间小学。",
      // slide_performance_2: "3603 名小学生。",
      // slide_performance_3: "59% 出席活动。",
      // slide_performance_4: "37% 参赛者完美的完成了所有课题。",
      // slide_performance_5: "568,240 道问题被解答了。",
      // slide_performance_6: "89% 道问题被正确的解答了。",
      // slide_performance_7: "平均一位参赛者解答了 267 道问题。",
      slide_pitch_title: '升级学校比赛',
      slide_pitch_description: '为学习增添一番欢乐',
      slide_pitch_button_host: '如何举办？',
      slide_point_title: "活动简介",
      slide_point_1: "非一般的教育活动，通过游戏方式进行，香甜可口适合小学生。",
      slide_point_2: "活动已有四年历史。84间华小使用过。",
      slide_point_3: "使用 KSSR Semakan 教学大纲。",
      slide_point_4: "一至六年级的学生皆可报名。没有人数限制。",
      slide_point_5: "任何学生完成所有课题可获得小礼物。",
      slide_point_6: "登记一次就可参与所有2024/2025学年的活动。",
      slide_point_7: "学生们在活动期间任何时间任何地点都可作答问题。",
      slide_social_hint_educator: "教育相关",
      slide_social_hint_follow: "追踪我们的最新资讯：",
      slide_social_hint_maker: "游戏开发相关",
      slide_syllabus_title: '问题库',
      slide_syllabus_name1: 'KSSR Semakan 小学一年级数学',
      slide_testimony_title: '校长们的感言',
      slide_testimony_1_name: "甘光马坡华小，郑碧婉校长",
      slide_testimony_1_message: "通过好几次与数学老师小组内的讨论，成功把此项数学游戏推介给甘光马坡华小低年级学生参与游戏，让小学生在新冠疫情底下，除了沉闷的网课学习，也有机会轻轻松松，快乐学习数学。更在21.6.2021-28.6.2021举行网上Hamochi比赛和赞助奖品和小礼物给冠亚军和参与学生。谢谢Edison及创设Hamochi的伙伴们给予马坡学生机会参与数学游戏和比赛＋奖项🙏感恩您们的付出🥰",
      slide_testimony_2_name: "关丹好孩子安亲班，林院长",
      slide_testimony_2_message: "这个系统让我的学生们在无时无刻，任何地方都可以进行复习或评估，节省了我不少的时间。成绩也让我一目了然，无需花精神体力去一个个批改。学生除了能和同伴竞争，也可以自己破自己的记录，再重复玩的时候，不知不觉便加深了课题的印象，记忆更永久。赞👍",
      slide_testimony_3_name: "邦咯岛华联2校，许依玲校长",
      slide_testimony_3_message: "谢谢Edison以及Hamochi主办单位给我们华联小学有这个机会参与这次的数学游戏。Hamochi是一项让学生能够边学边游戏的活动，让学生能够提高对数学的兴趣。这个游戏能够养成学生勤于动脑的习惯以及营造学生学数学及爱数学。谢谢。",
      slide_testimony_4_name: "邦咯岛华联1校，冯堰翎校长",
      slide_testimony_4_message: "<p>防疫非常时期，学校暂关闭，并展开居家学习和教学课程（PdPR）。这个假期，为了防控新型冠状病毒感染的肺炎，不少家庭选择了宅在家。孩子们没办法走街串门、出外玩耍，每天闷在家里，手机、电视、平板或电脑成为了\"解闷神器\"。为拯救濒临崩溃的父母，许多儿童教育平台开通免费资源，无论学习或玩乐，让孩子在家不无聊。</p><br/><p>感恩 Hamochi 在非常时期特别设置网站，提供本校低年级学生使用的免费线上学习平台。Hamochi是一个全面数码化的学习平台，这里的教学内容完全符合马来西亚的课程标准，家长们可以放心选择它。在这里，孩子们可以学习到各种和数学有关的知识。</p><br/><p>当我第一次使用时，我四岁的儿子在旁看到很兴奋，还不断催促我完成有关问题，好让他可以看到游戏结局。我觉得Hamochi很棒，它不但吸引小孩子，也让许多家长愿意陪同孩子们一起复习数学。当我闯关成功并得奖了，我的儿子问我，\"还有吗？再玩一次嘛！\"。由此可见，这些互动的数学游戏，不但能让孩子们学习到数学，也提升孩子们对学习数学的兴趣！</p><br/><p>宅家也要努力学习！爸爸妈妈们，别再让孩子一整天都抱着手机或是电脑玩游戏了，不如就和孩子约好适当的学习时间，这样宅家之余也不落下课业进度，还可以趁着机会学习更多课外的知识，一举两得！</p>",
      slide_testimony_parent_title: "家长的感言",
      slide_video_title: "活动视频",
      slide_v3_pitch_title: "Hamochi",
      slide_v3_pitch_description_1: "让学习成为一种习惯",
      slide_v3_pitch_description_2: "小学生们最喜爱的<br/>学校假期活动",
      slide_v3_pitch_description_3: "今年活动报名正式开放<br/><b class=\"primary--text\">已有84间小学报名</b>",
      slide_v3_pitch_button: "马上报名！",
    },
    PageMainLogin: {
      student_title: '我是学生',
      teacher_title: '我是老师 / 家长',
    },
    PageMainPage:{
      220901:{
        schools:"学校",
        sponsors:"赞助商",
      },
    },
    PageMainPricing: {
      title: "您的孩子值得拥有光明的未来！",
      cta_register: "立即开始！",
      cta_upgrade: "点击了解",
      plan_basic_name: "入门配套",
      plan_basic_price: "免费",
      plan_basic_promotion: "",
      plan_premium_name: "【前途无量】家庭配套",
      plan_premium_price: "RM 16.50 / 月",
      plan_premium_promotion: "年付可享 50% 优惠，截止日期为 ",
      feature_basic_family_account: "家庭账户",
      feature_basic_daily_math: "每日数学",
      feature_basic_school_event: "学校假期活动",
      feature_premium_storybook: "每月故事书",
      feature_premium_math_mastery: "精通数学",
    },
    PageMainRegister: {
      title: "学校注册",
      description: "我们的团队会为您的学校设立所有相关的账户和举办比赛。",
      message: "您好，我想为我的学校注册Hamochi账户以便举办线上比赛。",
      form:"https://docs.google.com/forms/d/e/1FAIpQLSetJnwQoSqV1_D5-KN8dY0K8n_zhT6-R5cogO-QR8awZYR5KQ/viewform?embedded=true",
    },
    PageMainUpgrade: {
      title: "为您的孩子解锁光明未来！",
      subtitle: "孩子们 ❤️ Hamochi。给与孩子们最棒的礼物。",
      plan_premium_name: "前途无量配套",
      plan_premium_description: "包含各式各样有趣又生动的功能，旨在提升您孩子的学习旅程：",
      plan_premium_shoutout: "让学习变得无比有趣！",
      plan_premium_price: "每月仅需 RM 16.50",
      plan_premium_promotion: "限时优惠！年付可享 50% 优惠，截止日期为 ",
      plan_premium_cta: "开启前途无量的旅程",
    },
    V6SlideUpgradeR2: {
      header: "释放您孩子的真正潜力",
      readingHabitTitle: "📚 培养阅读习惯 📖",
      readingHabitValue: "价值 RM 420.00",
      readingFeature1: "每月一本全新电子故事书",
      readingFeature2: "随书音频，朗读学习",
      readingFeature3: "包含英文、马来文和中文版本",
      mathSkillsTitle: "🔢 掌握数学技能 ✏️",
      mathSkillsValue: "价值 RM 600.00",
      mathFeature1: "完整小学六个学年的数学课程",
      mathFeature2: "趣味互动小游戏",
      // mathFeature3: "提升解决问题的能力",
      planName: "前途无量配套",
      planValue: "价值 RM 1,020",
      // planDuration: "一年份",
      originalPrice: "原价一年份 RM 360",
      discountedPrice: "现在仅需 RM 99！",
      // discountChip: "50% 限时优惠",
      // countdownPrefix: "剩",
      // countdownSuffix: "🔥",
      // countdownDays: "天",
      // countdownHours: "时",
      // countdownMinutes: "分",
      // countdownSeconds: "秒",
      upgradeButton: "立即升级并享受 72% 优惠",
      // giftMessage: "🎁 最棒的礼物！孩子们都爱 ❤️",
    },        
    PagePerformance:{
      no_items:"没有项目",
      performance_chart:"性能图表",
      performance_table:"性能表",
      star_calculation_message:" 少于 10 个问题或少于 60%"
    },
  },
  log :{
    'submission/add':"提交答卷",
    'player/add':"已创建玩家账号",
    'player/login':"登入",
  },
}